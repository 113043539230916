/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./header/header"
import Footer from './footer/footer';

const Layout = ({children, page}) => {
    return (
        <div className="page-wrapper">
            <div className="page-wrapper__inner">
                <div className="page-wrapper__inner-content">
                    <Header page={page} />
                    {children}
                </div>
                <Footer page={page} />
            </div>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
